<template>
  <div class="">
    <div class="navigation-sidebar">
      <ul class="nav flex-column">
        <img class="img-fluid" src="../../assets/images/logo-color-3x.png">
        <div class="row user-info">
          <template v-if="!user">
            <div class="icon-loading">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
            <div>
              <div class="name-loading"></div>
              <div class="emissions-loading"></div>
            </div>
          </template>
          <template v-else>
            <div class="icon-user">
              <i class="fas fa-user-tie"></i>
            </div>
            <div>
              <template v-if="user && user.name">
                <p class="name-user">Sr. {{ this.capitalize(user.name) }}</p>
              </template>
              <template v-if="user && user.name">
                <p class="emissions-user">Administração</p>
              </template>
            </div>
          </template>
        </div>
        <li class="nav-item">
          <router-link
            exact
            class="nav-link"
            exact-active-class="active"
            :to="{ name: 'home' }"
          ><i class="fas fa-chart-pie"></i>Resumo</router-link>
        </li>

        <li class="nav-item">
          <router-link
            exact
            class="nav-link"
            exact-active-class="active"
            :to="{ name: 'affiliates' }"
          ><i class="fas fa-users"></i>Afiliados</router-link>
        </li>

        <li class="nav-item">
          <router-link
            exact
            class="nav-link"
            exact-active-class="active"
            :to="{ name: 'users' }"
          ><i class="fas fa-building"></i>Clientes</router-link>
        </li>

        <li class="nav-item">
          <router-link
            exact
            class="nav-link"
            exact-active-class="active"
            :to="{ name: 'leads' }"
          ><i class="fas fa-fire-alt"></i>Leads</router-link>
        </li>

        <li class="nav-item">
          <a class="nav-item nav-link" @click="doLogout()" href="#" style="color: var(--red-light)"><i class="fas fa-sign-out-alt"></i>Sair</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import Notifications from './Notifications'

export default {
  components: {
    // Notifications
  },
  data: () => ({
    user: ''
  }),
  async mounted () {
    await this.getUser()
  },
  methods: {
    capitalize (data) {
      var capitalized = []
      var dataTemp = data + ''
      dataTemp.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      return capitalized[0] + ' ' + capitalized[1]
    },
    async doLogout () {
      this.$root.$emit('Spinner::show')
      await this.$firebase.auth().signOut()
      this.$router.push({ name: 'login' })
      this.$root.$emit('Spinner::hide')
    },
    async getUser () {
      await this.$firebase.database().ref(`admins/${window.uid}`).on('value', snapshot => {
        this.user = snapshot.val()
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.col-lg-2{
  padding: 0;
  margin: 0;
}

.dropdown{
  margin: 0 2px 8px 20px;
  &.actived{
    background-color: var(--background-gray);
    border-radius: 10px;
  }
  li{
    font-size: 10px !important;
  }
}

// .dropdown-active{
//   background-color: var(--green-light) !important;
//   border-radius: 10px;
// }
.navigation-sidebar{
  position: absolute;
  height: calc(100vh - 10px);
  // height: 100vh;
  overflow: hidden auto;
  width: 240px;
  border-radius: 15px;
  padding: 10px 5px;
  margin: 5px 0 5px 5px;
  box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);
  background-color: white;
  z-index: 100;
  &::-webkit-scrollbar {
    display: none !important;
  }
  .nav-link{
    cursor: pointer;
    color: var(--featured);
    padding: .70rem 0 .70rem 1rem !important;
    border-radius: 10px;
    transition: all .2s;
    font-size: 14px;
    font-weight: 400;
    &.active{
      background-color: var(--featured) !important;
      color: white;
      transition: all .3s;
      margin-right: -25px !important;
      z-index: 999;
      .new-box{
        font-size: 9px;
        font-weight: 500;
        color: var(--featured);
        background-color: white;
        border-radius: 3px;
        padding: 1px 4px;
        transform: translate(7px, -1px);
        position: absolute
      }
    }
    &:hover{
      background-color: var(--green-light);
      //box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05) !important;
    }
    &.disabled{
      background-color: #d9d9d9 !important;
      color: white;
    }

    .new-box{
      font-size: 9px;
      font-weight: 500;
      color: white;
      background-color: var(--featured);
      border-radius: 3px;
      padding: 1px 4px;
      transform: translate(7px, -1px);
      position: absolute
    }

  }
  i{
    height: 20px !important;
    width: 20px !important;
    font-size: 18.5px;
    margin-right: 13px;

  }
  .icon-ajust{
    margin-left: 2px;
    margin-right: 10px;
  }
  img{
    display: flex;
    width: 55%;
    justify-content: center;
    margin: 15px auto 20px;
  }
  li{
    margin: 3.5px 5px;
    letter-spacing: .25px;
  }
}

.user-info{
  background: var(--green-light);
  padding: 10px 10px;
  border-radius: 10px;
  margin: 0 -15px 5px 5px !important;
  .name-user{
    font-size: 12px;
    font-weight: 700;
    margin: 3px 0;
  }
  .emissions-user{
    font-size: 11px;
    font-weight: 600;
    margin: 3px 0;
    color: var(--dark)
  }
  .name-loading{
    background-color: #dbdbdb !important;
    height: 19px;
    width: 105px;
    border-radius: 3px;
    margin: 3px 0;
  }
  .emissions-loading{
    background-color: #dbdbdb !important;
    height: 18px;
    width: 100px;
    border-radius: 3px;
    margin: 3px 0;
  }
  .icon-user{
    background-color: var(--featured) !important;
    background: #ffc0c0;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    i{
      color: white;
      height: 45px;
      width: 45px !important;
      vertical-align: middle;
      item-align: center;
      line-height: 40px;
    }
  }
  .icon-loading{
    background-color: var(--featured) !important;
    background: #ffc0c0;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    i{
      margin: 0 !important;
      color: white;
      height: 45px !important;
      width: 45px !important;
      vertical-align: middle;
      item-align: center;
      line-height: 45px;
      font-size: 15px
    }
  }
}
</style>
